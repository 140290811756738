/* Style for the container div */
h1.title {
    text-align: center;
}

.tile_container, .bleachers_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 400px));
    gap: 30px; /* Adjust the gap between boxes as needed */
    justify-content: center; /* Center the grid horizontally */
    padding: 20px 10px;
}

/* Style for the boxes */
.tile {
    width: 100%;
    height: 100%;
    background-color: #fff; /* Example background color */
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 18px;
    color: black;
    min-height: 400px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-content: baseline;
    gap: 25px 10px;
}

.plant_icon {
    width: 45%;
    border-radius: 5px;
}

.plant_type_txt {
    color: #858585;
}

.plant_name_txt {
}

.summary_line {
    display: flex;
    justify-content: left;
    width: 49%;
    gap: 5px;
    align-items: center;
}

.recap_line {
    justify-content: flex-start;
    align-content: center;
    column-gap: 10px;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.table_header {
    width: 100%;
    color: #282c34;
    text-align: center;
}

.context_icon {
    height: 1.5em;
}

.plant_summary, .title_line {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 15px 0;
    align-content: center;
}

.title_line {
    justify-content: center;
    font-size: larger;
    gap: 0 10px;
}


.button_groups {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.nothing_found {
    text-align: center;
    margin: 35px 25%;
    display: block;
    border: 0 solid #e9e9ed;
    border-radius: 40px;
    padding: 20px;
}

.nothing_found > * {
    font-size: 28px;
    color: #545455;
    margin: auto auto;
    width: 100%;
    display: block;
}

.nothing_found > img {
    height: 150px;
    margin: 50px auto;
    display: block;
}

.nothing_suggest {
    font-size: 22px;
    margin: 10px auto;
}